<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2> Kik vagyunk<span> mi?</span></h2>
            <p>Mi vagyunk a <b>NAGY - ON FAIR Kft</b>. Sok éves tapasztalattal rendelkezünk redőnyök, szúnyoghálók gyártásában, telepítésében.</p>
            <p><br>Fő profilunk redőnyök, nyílászárók, szúnyoghálók tervezése, gyártása, beépítése. </p>
            <p><br>Cégünk saját gyártósorral rendelkezik, ezáltal <b>akár egy héten belül is</b> megkezdhető a beszerelés.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Miért válassza <span>cégünket?</span></h2>
                        <p>A Kft. több éves tapasztalattal, kiváló szakemberekkel rendelkezik. </p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Ingyenes felmérés</li>
                        <li><i class="fas fa-check"></i>Olcsó árak</li>
                        <li><i class="fas fa-check"></i>Saját gyártósor</li>
                        <li><i class="fas fa-check"></i>Kiváló szakemberek</li>
                        <li><i class="fas fa-check"></i>Teljes körű garancia</li>
                        <li><i class="fas fa-check"></i>Precíz beszerelés</li>
                        <li><i class="fas fa-check"></i>Gyors munkavégzés</li>
                        <li><i class="fas fa-check"></i>Szerelés, javítás 👨‍🔧</li>

                    </ul>
                    <p></p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.webp" alt="about">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="assets/video/promo.mp4" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>