<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav
    stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a href="/"><img src="logo.webp" style="height: 55px;border-radius: 20px;margin-left: -10px;"></a>

        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li style="margin-right: 120px;"  class="nav-item"><a href="tel:06303505566" class="nav-link" style="text-shadow: 0px 0px 6px rgba(255,255,255,0.7);"><i class="fa-solid fa-phone"></i>  +<u>36 30 350 5566</u></a></li>

                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Kezdőlap</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">Rólunk</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Szolgáltatások</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Referencia</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Ár kalkulátor</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('faq')">GYIK</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Kapcsolat</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Kezdőlap</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">Rólunk</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Szolgáltatások</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Referencia</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Ár kalkulátor</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('faq')">GYIK</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Kapcsolat</span></li>
            </ul>
        </div>
    </div>
</nav>