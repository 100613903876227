import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { EmailService } from 'src/app/email.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {




    constructor(private viewportScroller: ViewportScroller, private emailService: EmailService,private router: Router) { }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {

    }

    submit(f) {
        const name = document.getElementById('name') as HTMLInputElement | null;
        const email = document.getElementById('email') as HTMLInputElement | null;
        const subject = document.getElementById('subject') as HTMLInputElement | null;
        const number = document.getElementById('number') as HTMLInputElement | null;
        const message = document.getElementById('message') as HTMLInputElement | null;
        this.router.navigate(['koszonjuk']);
        this.emailService.submit(name.value, email.value, subject.value, number.value, message.value).subscribe((resp) => {
            
        });
    }


}