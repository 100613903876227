import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-koszonjuk',
  templateUrl: './koszonjuk.component.html',
  styleUrls: ['./koszonjuk.component.scss']
})
export class KoszonjukComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

  homeSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    items: 1,
    autoHeight: true,
    smartSpeed: 750,
    autoplay: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ]
  }

}