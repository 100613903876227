<div class="main-banner item-bg-one">
    <div class="creative-banner-three"></div>
    <particles [params]="myParams" [style]="myStyle" [width]="width" [height]="height"></particles>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <!-- <div style="display: flex;justify-content: center;"> -->
                <div class="main-banner-text">
                    <h2 style="color: #ffffff;font-weight: 600;text-shadow: 2px 1px #000000;">NAGY - ON FAIR Kft.</h2>
                    <h1 style="text-shadow: 2px 1px #000000;">
                        <ngx-typed-js
                            [strings]="['Szakértelem', 'Kedvező árak', 'Ingyenes felmérés', 'Ingyenes tervezés', 'Biztonság', 'Kényelem']"
                            [typeSpeed]="80" [loop]="true" [backSpeed]="50" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <h4 style="text-shadow: 3px 2px #000000;">Üdvözöljük a Nagy-On Fair Kft. weboldalán!</h4>
                    <p style="text-shadow: 3px 2px #000000;"><br>
                        Cégünk redőnyök, szúnyoghálók, nyílászárók gyártásával, beépítésével, árnyékolástechikával foglalkozik. </p>
                    <a (click)="onClick('about')" class="btn btn-primary">Rólunk</a>
                    <a (click)="onClick('pricing')" class="btn btn-primary view-work">Áraink</a>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>



<app-how-we-work></app-how-we-work>

<app-services></app-services>

<!-- <app-why-we-different></app-why-we-different> -->

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<!-- <app-blog></app-blog> -->

<!-- <app-partner></app-partner> -->

<!-- <app-subscribe></app-subscribe> -->

<app-contact></app-contact>