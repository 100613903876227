<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Nagy-On Fair Kft.</h4>
            <h2>Üdvözöllek kedves<span> látogató</span></h2>
            <p>Reméljük, hogy megtalálja nálunk az Ön számára megfelelő terméket, szolgáltatást és hogy hamarosan Önt is
                elégedett
                ügyfeleink között köszönthetjük. Ha bármilyen kérdése van, vagy szeretne további információkat kapni
                cégünkről és termékeinkről, akkor ne habozzon felvenni velünk a kapcsolatot.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-8 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Ingyenes felmérés</h3>
                    <p>Cégünk ingyenes felmérést és tervezést vállal az Ön számára. Szakértőink a helyszínen felmérik az
                        adott területet, majd segítenek megtervezni a legjobb megoldást az Ön igényei szerint. <br><br>A
                        szolgáltatásunkkal Önnek semmilyen kötelezettsége nincs, így bátran kérjen ingyenes felmérést
                        és tervezést tőlünk!</p>

                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-dollar-sign"></i>
                    </div>
                    <h3>Árkalkulátor</h3>
                    <p>Örömmel jelentjük be, hogy cégünk weboldalán elérhető az árkalkulátor és az árbecslő funkció.
                        Ezek a szolgáltatások lehetővé teszik, hogy gyorsan és egyszerűen kiszámíthassa, mennyibe
                        kerülne az általunk kínált redőnyök, egyéb termékek telepítése az Ön otthonában. Az árkalkulátor és az
                        árbecslő használata egyszerű, így Ön könnyedén tervezheti meg a költségvetését.</p>
                    <a (click)="onClick('pricing')" title="Read More" class="link-btn"><i
                            class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Minőség, szakértelem</h3>
                    <p>Cégünk számára rendkívül fontos a minőség és a precizitás minden munkafolyamatban. Szakembereink
                        az anyagok gyártásától, kiválasztásától kezdve a telepítésen át minden lépésnél kiemelt
                        figyelmet fordítanak
                        a részletekre. Ezáltal biztosítjuk, hogy minden nyílászáró a lehető legjobb minőségű legyen, és
                        tökéletesen illeszkedjen az Ön otthonához.</p>
                    <a (click)="onClick('work')" title="Read More" class="link-btn"><i
                            class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.webp" alt="image"></div>
</div>