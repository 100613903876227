<div class="demo-modal-panel">
    <button class="sidebar-demo-control" (click)="toggleClass()">
        <span>Kapcsolat</span>
    </button>
</div>

<div class="example-demo-modal" [class.active]="classApplied">
    <div class="inner">
        <h3>Telefonszám:</h3><br>
        <a  href="tel:06303505566">+36 30 350 5566</a><br><br>
        <h3>E-mail cím:</h3><br>
        <a  href="mailto:info@nagyonfairkft.hu">info@nagyonfairkft.hu</a>
    </div>
    <div class="header-title">
        <button class="example-demo-modal-control" (click)="toggleClass()">
            <i class="fas fa-times"></i>
        </button>
        <div class="title">Kapcsolat</div>
    </div>
</div>