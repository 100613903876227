<div id="faq" class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Gyakran ismételt <span>kérdések</span></h2>
            <p>Tájékozódjon rólunk, illetve amennyiben bármilyen további kérdés felmerül, keressen minket bizalommal!</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <accordion>
                        <accordion-group heading="Mennyi időbe telik egy redőny telepítése?">
                            <p>Az igény felmérést követően rövid határidőn belül tudjuk vállalni a telepítést, mivel
                                gyorsan, saját gyártásorral dolgozunk. <br>Általában a redőny telepítése körülbelül 1-2
                                órát vehet igénybe, de ez csak általános becslés. Nagyobb projektek, például egész
                                épületre történő redőnytelepítés vagy egyedi tervezésű redőnyök esetén a telepítési idő
                                hosszabb lehet.</p>
                        </accordion-group>
                        <accordion-group heading="Milyen árkategóriákban mozognak a redőnyök?">
                            <p>A piachoz mérten korrek és megfizethető áron dolgozunk. Általánosságban elmondható, hogy
                                a redőnyök árkategóriája közepes és magas között mozoghat. Fontos megérteni, hogy a
                                redőnyök árát befolyásolhatja a minőség, a tartósság, az energiahatékonyság és a
                                funkciók.</p>
                        </accordion-group>
                        <accordion-group heading="Milyen garanciát vállalnak a redőnyökkel kapcsolatban?">
                            <p>Teljes körű garanciát vállalunk termékeinkre 1 éven keresztül. Motoros redőnyökre 3 év
                                garancia érvényes.</p>
                        </accordion-group>
                        <accordion-group heading="Mennyire hatékonyak a redőnyök a hő- és hangszigetelés terén?">
                            <p>Redőnyök hatékonyak a hő- és hangszigetelés terén. Az alumínium redőnyök közötti
                                levegőrések és az alumínium lamellák hozzájárulnak a hőveszteség csökkentéséhez.<br>
                                A műanyag redőnyök zárt szerkezetük révén hatékonyan szigetelnek hő- és hangszigetelés
                                terén.<br>
                                Fontos azonban megjegyezni, hogy a redőnyök hatékonysága attól függ, hogy milyen
                                minőségű és megfelelően telepített redőnyökről van szó, valamint a helyes használat és
                                karbantartás is befolyásolja a hatékonyságot. Ezért érdemes minket választani, ahol
                                ezekre odafigyelünk.</p>
                        </accordion-group>
                        <accordion-group
                            heading="Milyen karbantartási és tisztítási teendők vannak a redőnyökkel kapcsolatban?">
                            <p>Karbantartási és tisztítási teendők a redőnyökkel kapcsolatban:</p>
                            <ol>
                                <li>Rendszeres tisztítás: Tartsa rendszeresen tisztán a redőnyök felületét. Használjon
                                    enyhe szappanos vizet és puha kefét vagy rongyot a lamellák vagy a redőnytok
                                    letörlésére.</li>
                                <li>Lamellák ellenőrzése: Időnként ellenőrizze a lamellákat esetleges sérülések,
                                    repedések vagy deformációk szempontjából. Ha problémát észlel, forduljon
                                    szakemberhez, hogy elvégezze a szükséges javításokat vagy cseréket.</li>
                                <li>Kenés: A redőnyök mechanizmusai időnként kenést igényelhetnek. Használjon szilikon
                                    spray-t vagy más ajánlott kenőanyagot a redőnytokban található mozgó alkatrészek
                                    kenésére. Ez segít a sima működés fenntartásában.</li>
                                <li>Ellenőrzés és beállítás: Időnként ellenőrizze a redőnyök működését és beállítását.
                                    Győződjön meg róla, hogy a redőnyök felső és alsó végállásai helyesen vannak
                                    beállítva. Ha bármilyen működési problémát vagy elmozdulást tapasztal, keresse fel
                                    egy szakembert a beállítások vagy javítások elvégzéséhez.
                                </li>
                                <li>Időszakos szerviz: Fontos időnként egy szakemberrel végeztetni a redőnyök átfogó
                                    szervizelését. Ez magában foglalhatja a mechanizmusok és alkatrészek ellenőrzését,
                                    tisztítását, kenését, valamint szükség esetén javítások elvégzését.</li>
                            </ol>

                            Fontos megjegyezni, hogy a karbantartási teendők és az időközök eltérhetnek az adott redőny
                            típusától és gyártójától függően. Ezért mindig érdemes konzultálni a redőnyök gyártójával
                            vagy egy szakemberrel a specifikus karbantartási és tisztítási útmutatókért és ajánlásokért.


                        </accordion-group>
                    </accordion>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img.webp" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>