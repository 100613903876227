<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Hogyan  <span>dolgozunk?</span></h2>
            <p>Egyszerűen, gyorsan, megbízhatóan, olcsón és hatékonyan.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-ruler"></i>
                    </div>
                    <h3>Kapcsolatfelvétel, tervezés</h3>
                    <p>Telefonon vagy emailben kereshetnek minket, hogy megkezdjük az egyeztetést és megbeszéljük az ügyfél igényeit és elvárásait. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Helyszíni felmérés</h3>
                    <p>Egyeztetünk egy időpontot, hogy szakembereink elmenjenek az ügyfél helyszínére, pontos méréseket végezzenek és részletesen megbeszéljék a telepítés részleteit.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fa-solid fa-house-chimney-window"></i>
                    </div>
                    <h3>Termékbeszerelés</h3>
                    <p>Miután a termék elkészült, szakembereink elvégzik a telepítést, gondoskodva arról, hogy minden a megbeszéltek szerint történjen, és a legmagasabb minőségi színvonalat nyújtsuk az ügyfélnek.</p>
                </div>
            </div>
            
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.webp" alt="image"></div>
</section>