import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],

})
export class PricingComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
  createForm: FormGroup = new FormGroup({
    szelesseg: new FormControl(100, [Validators.required, Validators.min(1)]),
    magassag: new FormControl(100, [Validators.required, Validators.min(1)]),
    enable: new FormControl(false),
    alma: new FormControl
  });

  isChecked = false;
  players = [
    { name: 'Szimpla műanyag redőny', price: 2.2 }, //10.000cm^2-re Ha 10.000-re 22.000cm^2 akkor 1-re = 2,2
    { name: 'Kombi műanyag redőny', price: 2.9 },
    { name: 'Szimpla alumínium redőny', price: 3.4 },
    { name: 'Kombi alumínium redőny', price: 4.1 },
    // {name: 'Vakolható redőny', price: 22000},
    /* { name: 'Motoros redőny', price: 3.5 }, */
    { name: 'Rolós szúnyogháló', price: 2.4 },
    { name: 'Oldalhúzós, nyíló ajtó', price: 2.7 },
    { name: 'Alumínium fixek', price: 1.7 },
    { name: 'PVC fix', price: 1.3 },
  ]
  selected = 2.2;
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  ngOnInit() {
    this.uniquePhoneNumberValidator();
    this.uniquePhoneNumberValidator2();

  }

  update(e) {
    this.selected = Number(e.target.value)
  }
  myCheck() {

    this.isChecked = !this.isChecked;
  }

  submit(f) {

  }

  uniquePhoneNumberValidator(): void {
    const control = this.createForm.get('szelesseg');
    const validateSub = control.valueChanges
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        switchMap(async (value) => (value < 1)),
        map((result: boolean) => {
          if (result) {
            this.createForm.get('szelesseg').setValue(1);

          } else {
            control.setErrors(null);
            return null;
          }
        })
      ).subscribe();
  }

  uniquePhoneNumberValidator2(): void {
    const control = this.createForm.get('magassag');
    const validateSub = control.valueChanges
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        switchMap(async (value) => (value < 1)),
        map((result: boolean) => {
          if (result) {
            this.createForm.get('magassag').setValue(1);

          } else {
            control.setErrors(null);
            return null;
          }
        })
      ).subscribe();
  }

  handleInputChange() {
    var element = <HTMLInputElement>document.getElementById("is3dCheckBox");
    var isChecked = element.checked;
    if (isChecked == true)
      console.log("Checked");
    if (isChecked == false)
      console.log("Not Checked");
  }


}
