<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Ár <span>Kalkulátor</span></h2>
            <p>Az alábbi kalkulátor segítségével tájékozódhat, hogy bizonyos redőny szolgáltatásainkra milyen becsült ár
                várható.</p>
        </div>
        <div class="row justify-content-center">
            <div class="contact-form">
                <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 form-group">
                            <h3>Redőny típusa</h3>
                            <select class="form-control" #cricket (change)="update($event)">
                                <option class="asd" *ngFor="let player of players" [value]="player.price">
                                    {{player.name}}
                                </option>
                            </select>


                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="form-group mb-3">
                                <h3>Szélesség (cm)</h3>
                                <input type="number" class="form-control" min="1"
                                    [formControl]="createForm.controls.szelesseg">
                                <div class="alert alert-danger"
                                    *ngIf="createForm.controls.szelesseg.hasError('required')">Kötelezően
                                    megadandó.</div>
                                    <div class="alert alert-danger" *ngIf="createForm.controls.szelesseg.hasError('min')">
                                        Minimum 1 cm</div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="form-group mb-3">
                                <h3>Magasság (cm)</h3>
                                <input type="number" class="form-control" min="1"
                                    [formControl]="createForm.controls.magassag">
                                <div class="alert alert-danger"
                                    *ngIf="createForm.controls.magassag.hasError('required')">Kötelezően
                                    megadandó.</div>
                                <div class="alert alert-danger" *ngIf="createForm.controls.magassag.hasError('min')">
                                    Minimum 1 cm</div>
                            </div>
                        </div>
                        <br>
                        <div class="col-lg-3">
                            <label class="container">
                                <div class="row">
                                    <mat-slide-toggle [formControl]="createForm.controls.enable">
                                        <p style="margin-top: 10px;">
                                            Vakolható redőny
                                        </p>
                                    </mat-slide-toggle>

                                </div>
                            </label>
                        </div>
                        <div class="col-lg-12"></div>
                        <div class="row">

                            <div class="col-lg-3 col-md-6">
                                <app-round-number [text]="'Becsült ár:'" *ngIf="!createForm.controls.enable.value"
                                    [egyseg]="'Ft'" [eredeti]="selected * 114 * 114" 
                                    [value]="selected * createForm.controls.magassag.value * createForm.controls.szelesseg.value"></app-round-number>
                                <app-round-number [text]="'Becsült ár:'" *ngIf="createForm.controls.enable.value" [egyseg]="'Ft'" [eredeti]="selected * 114 * 114" 
                                    [value]="(selected+0.3) * createForm.controls.magassag.value * createForm.controls.szelesseg.value"></app-round-number>
                            </div>
                            <div class="col-lg-3">
                                <app-round-number [text]="'Méret:'" [egyseg]="'asd'" [ize]="true" 
                                    [value]="createForm.controls.magassag.value * createForm.controls.szelesseg.value/10000"></app-round-number>
                            </div>

                        </div>
                        <div class="col-lg-12" style="margin-top: 20px;"></div>
                        <p>Az ár bruttóban értendő, és <b>magába foglalja az anyagköltséget, felmérést és munkadíjat is.</b></p>
                        <p style="font-size: 15px;"> <i> <b>Motoros redőny</b> esetén az alumínium redőny árát kell figyelembe venni. + 40.000 Ft a motor.</i></p>






                    </div>
                </form>
            </div>

        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.webp" alt="image"></div>
</div>