<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/ref2.webp" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Ne habozzon</h4>
                        <h2>Kérjen ajánlatot <span>még ma!</span></h2>
                        <p>Hívjon a <b><u><a href="tel:+36303505566">+36 30 350 5566</a></u></b> számon,<br> vagy vegye fel
                            velünk a kapcsolatot az <a
                                href="mailto:info@nagyonfairkft.hu"><b><u>info@nagyonfairkft.hu</u></b></a> e-mail
                            címen.</p>
                    </div>
                    <a (click)="onClick('services')" class="btn btn-primary view-work">Termékek</a>
                    <br><br>
                    <h3>Segítünk elképzeléseiben!</h3>
                    <p>Tapasztalatunk és szaktudásunk alapján javaslatot teszünk, hogy az ügyfél számára melyik megoldás lenne optimálisabb és jobban megfelelne igényeinek.</p>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape2.webp" alt="image"></div>
</div>