<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Dolgozni csak pontosan, szépen, ahogy a csillag megy az égen, úgy érdemes.”</p>
                        <div class="info">
                            <h3>József Attila</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“"A redőnyök nem csak az ablakokat, hanem az otthonunkat is megvédik a külvilág zavaró hatásaitól.”</p>
                        <div class="info">
                            <h3>Anthony D. Williams

                            </h3>

                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“A munka legfontosabb része a kezdet.”</p>
                        <div class="info">
                            <h3>Platón</h3>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>