<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/">NAGY-ON FAIR <span>Kft.</span></a></h3>
        <ul>
            <li><a href="https://www.facebook.com/profile.php?id=100071864209339" target="https://www.facebook.com/profile.php?id=100071864209339" class="fab fa-facebook-f"></a></li>
            <li><a href="https://www.tiktok.com/@profiredony" target="https://www.tiktok.com/@profiredony" class="fab fa-tiktok"></a></li>
        </ul>

        <p><a href="tel:06303505566">+ 36 30 350 5566</a></p>
        <p>© Minden jog fenntartva </p>
    </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>