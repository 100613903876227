<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg-two">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h4>Nagy-On Fair Kft.</h4>
                                <h1>Köszönjük a kapcsolatfelvételt. </h1>
                                <h3 style="color: aliceblue;">Hamarosan keressük <span>Önt!</span></h3>

                                <a href="#contact" style="margin-top: 30px;" class="btn btn-primary">Vissza</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>