<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Referencia <span>Munkák</span></h2>
            <p>Ebben a fejezetben találhatók meg a referencia munkáink, amelyeket büszkén mutatunk be. Itt megtekintheti az általunk végzett projekteket, inspirálódhat és meggyőződhet a minőségről és a szakmai hozzáértésünkről.</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="Összes">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.webp" alt="work-img">
                                
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img7.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img8.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img9.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img10.webp" alt="work-img">
                                
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img12.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img13.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img14.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img15.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img16.webp" alt="work-img">
                                
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Redőny">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img9.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img10.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img13.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img14.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img15.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img16.webp" alt="work-img">
                                
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Szúnyogháló">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img12.webp" alt="work-img">
                                
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Kombi">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img7.webp" alt="work-img">
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.webp" alt="work-img">
                                
                            </div>
                        </div>
                    </div>
                </ngx-tab>

            </ngx-tabset>
        </div>
    </div>
</div>