<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4 style="color: white;">A legjobb helyen jár!</h4>
                <h2>Garancia minden termékre!</h2>
                <p>1 év teljeskörű garancia minden termékre motorokra 3 év.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Kapcsolat</a>
                <a (click)="onClick('work')" class="btn btn-primary view-work">Referencia</a>
            </div>
        </div>
    </div>
</div>