import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class EmailService {
  constructor(private http: HttpClient) { }

  submit(name, email, subject, number, message): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post('https://formspree.io/f/xgebrgjn',
      { name: name, replyto: email, message: 'Telefonszám:' + '\n' + number + '\n\nTárgy:\n' + subject + '\n\nÜzenet:\n\n' + message },
      { 'headers': headers })
      .pipe(map(() => {confirm("Email sikeresen elküldve !"); }));

  }

}