<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Cégünk <span>szolgáltatásai</span></h2>
            <p>Engedje meg, hogy röviden bemutassuk Önnek szolgáltatásainkat, amivel az Ön igényeinek eleget tudunk tenni.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-ruler"></i>
                    <h3>Ingyenes felmérés, tervezés</h3>
                    <p>Ingyenesen végezzük a felmérést ügyfeleink számára, felmérést követően küldjük az árajánlatot.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-house-chimney-window"></i>
                    <h3>Redőnyök</h3>
                    <p>Műanyag, alumínium redőnyök tervezése, gyártása, beszerelése.</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-mosquito"></i>
                    <h3>Szúnyoghálók</h3>
                    <p>Szúnyoghálós redőnyök, műanyag, alumínium peremes szúnyoghálók tervezése, gyártása, beszerelése.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-door-open"></i>
                    <h3>Nyílászárók</h3>
                    <p>Nyílászárók beszerelése igény szerint.</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-warehouse"></i>
                    <h3>Garázskapuk, motorok</h3>
                    <p>Motorikus garázskapuk teljes körű beszerelése igény szerint</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-shield-halved"></i>
                    <h3>Garancia, szerelés</h3>
                    <p>Termékeinkre 1 éves garancia érvényes. Foglalkozunk szereléssel is.</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>