<div class="skill-area ptb-100 bg-fffdfd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="skill-content">
                    <div class="section-title">
                        <h2>Kövess <span>Minket</span></h2>
                        <p>Keresd <b>TikTok</b> és <b>Facebook</b> oldalainkat, ahol találkozhattok munkáinkkal, referenciáinkkal.</p>
                    </div>
                   <h4><u><a target="_blank" title="Kövess a Facebookon!" href="https://www.facebook.com/profile.php?id=100071864209339"><img alt="follow me on facebook" src="https://marketing.create-cdn.net/assets/facebook40x40.png" border=0></a> <a target="_blank" href="https://www.facebook.com/profile.php?id=100071864209339">Facebook</a></u></h4>
                   <br>
                   <h4><u><a target="_blank" title="Kövesd TikTok oldalunkat!" href="https://www.tiktok.com/@profiredony"><img style="border-radius: 5px;" alt="follow me on facebook" src="assets/img/tiktok.webp" border=0></a> <a target="_blank" href="https://www.tiktok.com/@profiredony">Tiktok</a></u></h4>

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="skill-video" >
                    <img src="assets/img/video-img.webp" alt="video-img" style="border-radius: 20px;">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupTwo').open()"><i class="fas fa-play"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupTwo [identifier]="'popupTwo'">
        <iframe src="assets/video/promo2.mp4" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>
<div class="shape2"><img src="assets/img/shape2.webp" alt="image"></div>
