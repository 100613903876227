import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-round-number',
  templateUrl: './round-number.component.html',
  styleUrls: ['./round-number.component.scss']
})
export class RoundNumberComponent implements OnInit, OnChanges {

  @Input() value;
  @Input() text;
  @Input() egyseg;
  @Input() ize = false;
  @Input() eredeti;


  ngOnInit() {
    this.value = Math.round(this.value * 100) / 100;

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.ize) {

      this.value = Math.round(this.value * 100) / 100;

    } else {
      this.value = Math.round(this.value);
      if (this.value < this.eredeti) {
        this.value = Math.round(this.eredeti);
      }
    }
  }
}
