<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Redőnyök, <span>Szúnyoghálók</span></h2>
            <p>Elérhetőek a következő termékek:</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Műanyag redőny</h3>
                        <p>A műanyag redőny hasznos, mert segít szabályozni a szobák hőmérsékletét és megakadályozza a hő áramlását, így csökkentheti a fűtési és hűtési költségeket. Emellett a redőnyök szigetelő hatással is rendelkezhetnek, minimalizálva a zajszintet és védelmet nyújtva a külső környezeti tényezők, például a napfény, az eső vagy a szél ellen.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img2.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Műanyag kombi redőny</h3>
                        <p>A műanyag kombi redőny egy speciális típusú redőny, amely kombinálja a műanyag redőny és a szúnyogháló funkcióit. Ez a redőnyrendszer lehetővé teszi a helyiségek szellőztetését anélkül, hogy a szúnyogok vagy más rovarok bejutnának. A műanyag kombi redőny egy rugalmas és praktikus megoldás, amely védelmet nyújt a hő, a zaj és a rovarok ellen egyaránt. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img3.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Alumínium redőny</h3>
                        <p>
                            Az alumínium redőny tartós, ellenáll az időjárás viszontagságainak és biztosít védelmet a betörések ellen. Emellett hőszigetelő tulajdonságokkal rendelkezik, csökkenti a zaj behatolását és esztétikus megjelenést kölcsönöz az épületnek.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img4.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Alumínium kombi redőny</h3>
                        <p>Az alumínium kombi redőny rugalmas és praktikus, hő-, zaj- és rovarvédelmet nyújtva egyidejűleg.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img5.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Fix műanyag szúnyogháló</h3>
                        <p>A fix műanyag szúnyogháló egy fixen rögzített szúnyogháló, amelyet ablakokra vagy ajtókra helyeznek. Ez a háló védelmet nyújt a rovarok és más kis lények ellen, miközben lehetővé teszi a friss levegő áramlását és a természetes fény beáramlását a helyiségbe. A fix műanyag szúnyogháló könnyen karbantartható, tartós és praktikus megoldás, amely segít megőrizni a kellemes légáramlást és védelmet nyújt a bosszantó rovarok ellen.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img6.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Fix alumínium szúnyogháló</h3>
                        <p>A fix alumínium szúnyogháló kiváló választás, mert tartós, könnyen karbantartható és hatékonyan védi a helyiséget a rovaroktól. Az alumínium anyag ellenáll az időjárásnak, és hosszú élettartammal rendelkezik.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img7.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Alumínium peremes szúnyogháló (belső klipszes)</h3>
                        <p>A peremek alumínium kerettel vannak ellátva, ami megerősíti és stabilizálja a hálót. A belső klipszes kialakítás azt jelenti, hogy a hálót belülről rögzítik az ablak vagy az ajtó keretéhez, így egyszerűen és biztonságosan telepíthető anélkül, hogy bármilyen látható csavarokat vagy rögzítéseket igényelne.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img8.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Rolós, lehúzható szúnyogháló</h3>
                        <p>
                            A rolós, lehúzható szúnyogháló hatékonyan védi a helyiséget a rovaroktól, könnyű használatot biztosítva a háló lehúzása vagy feltekerése révén. Emellett esztétikus megjelenést kölcsönöz az ablaknak vagy ajtónak, és lehetővé teszi a friss levegő áramlását anélkül, hogy a rovarok bejutnának.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img9.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Rolós oldalra húzható szúnyogháló ajtó</h3>
                        <p>Egyszerűen oldalra húzható, így könnyedén áthaladhatunk az ajtón anélkül, hogy a hálót teljesen fel kellene tekerni. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img10.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Nyíló szúnyogháló ajtó</h3>
                        <p>A nyíló szúnyogháló ajtó egy olyan típusú ajtó, amely speciálisan szúnyoghálóval van ellátva, hogy megakadályozza a rovarok és más kis lények bejutását a helyiségbe. Ez az ajtó könnyen nyitható és zárható, és általában hasonló módon működik, mint egy hagyományos ajtó.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img11.webp" alt="services-img">

                    </div>
                    <div class="services-content">
                        <h3>Toló szúnyogháló ajtó</h3>
                        <p>A toló rendszerű kialakítás lehetővé teszi a könnyű és sima mozgást, és ideális megoldás lehet, ha korlátozott hely áll rendelkezésre az ajtó kinyitásához.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>